p {
  margin-top: 0rem;
  margin-bottom: 0.25rem;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
  background-color: rgb(144, 204, 204) !important
}

.slick-next {
  right: 3% !important;
  z-index: 1;
  background-color: rgb(144, 204, 204) !important
}

/* */
html {
  background: #F3F3F3
}

.bodyLogin {
  background: #F3F3F3 url("../src/Images/FinaeonLoginBG_black.png") repeat-x center top;
}

.tab button:hover {
  background-color: #ddd !important;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc !important;
}

.recharts-responsive-container {
  padding: 10px;
  width: 100%;
  height: 100%;
  max-height: 480px;
  /* background-color: #fff;   NEW ONE: #ccf2ff*/
  /* background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #B8E4E4); */
  /* use as back up: rgb(182, 237, 233)*/
  border-radius: 10px;
  background-color: white;
}

.no-background {
  background-image: none
}

.half-container {
  max-height: 240px;
}

.large-graph-container {
  max-height: 960px;
}

.cap-area-graph-container {
  max-height: 720px;
}

.css-1gictic-MuiPaper-root {
  color: black !important
}

legend {
  width: auto !important
}

.loading {
  margin: 20px;
  font-size: 36px;
  font-family: sans-serif;
}

.loading:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '';
  }
}

.MuiCircularProgress-svg{
  color: #4b4b4b;
}

p::selection{
  background: #4b4b4b;
  color: #FFFFFF;
}

div::selection{
  background: #4b4b4b;
  color: #FFFFFF;
}

td::selection{
  background: #4b4b4b;
  color: #FFFFFF;
}

.MuiTableSortLabel-root{
  color:rgba(0,0,0,.87) !important;
}

.MuiPickersYear-yearButton.Mui-selected{
  background-color: #4b4b4b !important;
  color:#FFFFFF;
}

.MuiPickersDay-root.Mui-selected{
  color: #FFFFFF !important;
  background-color: #4b4b4b !important;
}

.MuiPickersDay-dayWithMargin.MuiPickersDay-today{
  background-color: rgba(209,243,254,0.5) !important;
  color: #4b4b4b !important;
  border: 1px solid #4b4b4b !important;
}

.MuiPickersDay-dayWithMargin:hover{
  background-color:#ff0000;
}

.MuiLink-root.MuiLink-underlineAlways{
  color: #004D66 !important;
}

.nav-link-account{
  color:#4b4b4b !important;
  text-decoration: none;
}

.nav-link-account:hover{
  color: #0086b3;
  background-color: #f5fcfe;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.wider .modal-content {
  width: 120%;
}

td>span.MuiCheckbox-root {
  padding-left: 9px !important;
}

.typographyStyle {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.recharts-legend-item {
  white-space: nowrap;
}

th {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 7;
}
